.maps {
  overflow: hidden;
  position: relative;
  min-height: 570px;
  height: calc(100vh - 140px - 120px);

  .container, .container .-relative {
    min-height: inherit;
    height: inherit;
  }
}

.maps-map {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.maps-desc-box {
  position: absolute;
  z-index: 20;
  top: 100px;
  left: 0;
  width: auto;
}

.maps-desc {
  display: inline-block;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  padding: 35px;
  width: 350px;
  max-width: 350px;
  font-size: 14px;
}

.maps-desc__head {
  font-size: 24px;
  letter-spacing: 1.2px;
  font-weight: 700;
  margin-bottom: 10px;
}

.maps-desc__card {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,.05);

  &__name {
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__address {
    margin-bottom: 5px;
  }
  &__rating {
    margin-bottom: 5px;
  }
  &__comment {
    font-size: 12px;
    color: #c7c7c7;
  }
  &__map {
    text-align: right;
    font-size: 12px;
    color: #c7c7c7;
    margin-top: -15px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & ._map-point {
    transform: scale(0.75);
    position: relative;
    top: 6px;
  }
}

.maps-desc__phone {
  margin-bottom: 5px;
}
.maps-desc__mail {
  margin-bottom: 5px;
}

@media (max-width: 991.98px) {
  .maps {
    height: calc(100vh - 84px - 80px);
  }
  .maps-desc-box {
    top: auto;
    margin-top: 0;
    bottom: 0;
  }
  .maps-desc {
    border-radius: 20px 20px 0 0;
  }
}
@media (max-width: 767.98px) {
  .maps-desc__head {
    display: none;
  }
  .maps-desc__card {
    padding: 0;
    margin-top: -10px;
    background: none;
    box-shadow: none;
  }
  .maps-desc__card__address {
    margin-top: 0;
  }
  .maps {
    height: calc(100vh - 40px - 60px);
  }
  .maps-desc-box,
  .maps-desc {
    width: 100%;
  }
  .maps-desc-box {
    bottom: -80px;
    transition: bottom .5s;

    body.-open-contact-card & {
      bottom: 0;
    }
    text-align: center;
    & > * {
      text-align: left;
    }
  }
}