.top {
  box-sizing: border-box;
  box-shadow: 0 0 12px rgba(0,0,0,0.05);
  padding: $menuVPad 0;
  height: $menuHeight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $menuBg;
  z-index: $menuZIndex;

  @include minWidth();

  @media (max-width:991.98px) {
    padding: $menuVPadTab 0;
    height: $menuHeightTab;
  }
  @media (max-width:767.98px) {
    padding: $menuVPadMob 0;
    height: $menuHeightMob;
  }
}

.top-logo {
  display: block;
  svg {
    width: 130px;
    height: 85px;
    @media (max-width:991.98px) {
      width: 70px;
      height: 47px;
    }
    @media (max-width:767.98px) {
      width: 70px;
      height: 35px;
    }
  }
}

.top-menu {
  margin-bottom: 11px;

  &__lang {
    text-align: right;
    margin-right: 35px;
    margin-bottom: 20px;
    font-size: 13px;
    position: relative;
    z-index: 20;

    &__selected {
      display: inline-block;
      color: #fff;
      background: #333;
      padding: 3px 10px;
      border-radius: 14px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 11px;
      margin-left: 8px;
      position: relative;
      z-index: 30;
    }

    ul {
      @include fade(1s);

      position: absolute;
      right: 0;
      top: 16px;
      z-index: 10;
      padding: 6px 10px;
      border-radius: 9px;
      box-shadow: $boxShadow;
      list-style: none;
      background: #333;
      color: #fff;
      text-transform: uppercase;

      li {
        padding: 4px 0;
      }
    }

    &.-open-lang ul {
      @include fadeOut(1s);
    }

    @media (max-width:991.98px) {
      @include fade(0s);
      margin-right: 0;

      body.-open-menu--mob & {
        @include fadeOut(.5s);
      }
    }
  }
}

.top-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 10;

  li {
    display: inline-block;
    font-size: 20px;
    color: #333;
    margin: 0 10px;
    position: relative;
    z-index: 20;
  }

  .tml-line {
    position: absolute;
    bottom: -6px;
    transition: all .8s;
    width: 0;
    left: 0;
    background: #3B464A;
    border-radius: 4px 0 4px 0;
    height: 4px;
    margin-left: 12px;
  }

  .top-contact__btn {
    display: none;
  }

  @media (max-width:991.98px) {
    position: absolute;
    z-index: 10;
    left: 5px;
    top: -600px;

    @include fade(.5s);

    .top-contact__btn {
      position: fixed;
      bottom: 40px;
      left: 50%;
      margin-left: -100px;
      box-shadow: $boxShadow;
      background: $gray;

      body.-open-menu--mob & {
        display: block;
      }
    }

    body.-open-menu--mob & {
      top: 59px;
      @include fadeOut(.5s);
    }

    .tml-line {
      display: none;
    }

    li {
      display: block;
      font-size: 24px;
      margin: 0.5em 0;
      .-act {
        transition: color .5s;
        color: $green;
      }
    }

    &:before {
      content: '';
      position: fixed;
      z-index: 0;
      top: $menuHeightTab;
      bottom: 0;
      left: 0;
      right: 0;
      background: $menuBg;
      @include fade(.5s);

      body.-open-menu--mob & {
        @include fadeOut(.5s);
      }
    }
  }

  @media (max-width:767.98px) {
    &:before {
      top: $menuHeightMob;
    }

    li {
      font-size: 20px;
    }

  }

}

.top-contact {
  color: #333;
  display: flex;
  flex-direction: column;

  &__mail {
    display: block;
    font-size: 15px;
    text-align: right;
  }
  &__phone {
    display: block;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
  }
  &__btn {
    float: right;
    position: relative;
    left: 5px;
    margin-top: 8px;
    width: 140px;

  }
  @media (max-width: 991.58px) {
    flex-direction: column-reverse;

    &__mail {
      position: relative;
      top: -3px;
    }
    &__btn {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    &__mail {
      font-size: 11px;
    }
    &__phone {
      font-size: 13px;
    }
  }
}

.top__sandwich {
  font-size: 18px;
  letter-spacing: 0.2px;
  color: #3B464A;

  svg {
    width: 32px;
    height: 21px;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }

  body:not(.-open-menu--mob) & {
    .__close {
      display: none;
    }
  }
  body.-open-menu--mob & {
    .__sandwich {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    top: -3px;

    svg {
      margin-right: 3px;
      width: 20px;
      height: 14px;
    }
  }

}

.top-grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  @media (max-width:991.98px) {
    align-items: center;
  }

  &__logo {
    @media (max-width:991.98px) {
      margin: 0 auto;
      position: relative;
      z-index: 30;
      top: 2px;
    }

    @include fadeOut(.5s);

    body.-open-menu--mob & {
      @include fade(.5s);
    }
  }
  &__menu {
    @media (max-width:991.98px) {
      position: absolute;
      z-index: 0;
      top: 19px;
      left: 0;
      right: 0;
    }
    @media (max-width:767.98px) {
      top: 7px;
    }
  }
  &__contact {
    @include fadeOut(.5s);

    body.-open-menu--mob & {
      @include fade(.5s);
    }
  }
  &__menuIco--mob {
    position: relative;
    z-index: 10;

    @media (min-width: 992px) {
      display: none;
    }
  }

}