body.-open-popup-form > .-hid {
  transition: filter 1s;
  filter: blur(10px);
}
body.-open-popup-form .popup-form {
  @include fadeOut(.5s);
}

@media (min-width: 768px) {
  .popup-form .pf-cont .popup-form__order {
    width: 600px;
    margin-left: -120px;
  }
  .pf-two-col {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    & > * {
      flex: 47% 0;
      width: 50%;
      box-sizing: border-box;
    }
  }
}

.popup-form {
  position: absolute;
  margin-top: 100px;
  top: 0;
  left: 50%;
  margin-left: -180px;
  width: 360px;
  z-index: $zIndexPopup;
  @include fade(.5s);

  @media (max-width: 399.98px) {
    left: 20px;
    right: 20px;
    margin-left: 0;
    width: auto;
  }

  .pf-overlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
  }
  .pf-cont {
    position: relative;
    z-index: 10;
  }
  .pf-close {
    position: fixed;
    top: 50px;
    right: 50px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.pf {
  &__head {
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    color: #fff;
  }
  &__bg {
    overflow: hidden;
    border-radius: 15px;
    padding: 40px;
    position: relative;
    & > * {
      position: relative;
      z-index: 10;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.3);
    }
  }
  &__text {
    font-size: 14px;
    text-align: center;
    margin: 0 0 30px 0;
    color: #fff;
  }
  &__loader {
    @include fade(.5s);
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.3);

    .sk-fading-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -20px 0 0 -20px;
    }

    .popup-form.-load & {
      @include fadeOut(.5s);
    }
  }
  .popup-form.-success &__text,
  .popup-form.-error &__text {
    visibility: hidden;
  }

  &__message {
    display: none;
    font-size: 14px;
    text-align: center;
    margin: 0 0 30px 0;
    color: #fff;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;

    .popup-form.-success &,
    .popup-form.-error & {
      display: block;
    }


    &__success {
      display: none;
      color: $green;
      .popup-form.-success & {
        display: block;
      }
    }
    &__error {
      display: none;
      color: #ff604b;
      .popup-form.-error & {
        display: block;
      }
    }
  }

  &__input {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 21px;
    margin-bottom: 30px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    height: 38px;
    padding: 0 19px;
    position: relative;

    &.__textarea {
      height: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.-error {
      border: 1px solid $red;
      input, textarea {
        color: $red;
      }
    }
    &.-error &__error {
      display: block;
    }
    &__error {
      display: none;
      position: absolute;
      top: -20px;
      left: 20px;
      font-size: 11px;
      line-height: 15px;
      color: #ff604b;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    input, textarea {
      border: 0;
      background: none;
      line-height: 40px;
      display: block;
      width: 100%;
      font-size: 14px;
      color: rgba(59, 70, 74, 1);
      outline: none;
    }
    textarea {
      line-height: 1.4;
      resize: none;
      min-height: 80px;
      max-height: 80px;
      height: 80px;
    }
  }
  &__btn {
    input.btn {
      width: 100%;
    }
  }
}