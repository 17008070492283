$btnBoxShadow: 0 0 12px rgba(0,0,0,0.2);
$boxShadow: 0 0 8px rgba(0,0,0,0.4);

$menuHeight: 140px;
$menuVPad: 20px;

$menuHeightTab: 84px;
$menuVPadTab: 16px;

$menuHeightMob: 45px;
$menuVPadMob: 5px;

$menuBg: rgba(256,256,256, 1.95);
$menuZIndex: 600;

$zIndexPopup: 1000;

$gray: #3B464A;
$green: #00A874;
$red: #FF2305;

@mixin fade($d:.5s) {
  transition: visibility .01s ($d + 0.1s), opacity $d;
  visibility: hidden;
  opacity: 0;
}
@mixin fadeOut($d:.5s) {
  transition: visibility .01s .01s, opacity $d;
  visibility: visible;
  opacity: 1;
}

@mixin minWidth() {
  @media (min-width:992px) {
    min-width: 992px;
  }
  @media (max-width:991.98px) {
    min-width: 320px;
  }
}
