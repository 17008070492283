.container {
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width:768px) {
    padding: 0 30px;
  }
  @media (min-width:992px) {
    padding: 0 50px;
    min-width: 892px;
    max-width: 1340px;
  }
}

.container-small {
  @media (min-width:992px) {
    width: 892px;
  }
}
