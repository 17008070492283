.foot {
  height: 80px;
  background: #3B464A;
  padding: 20px 0;
  .container {
    display: flex;
    justify-content: space-between;
  }
}
.foot-contact {
  color: #fff;
  text-align: right;

  &__phone {
    font-size: 24px;
  }
  &__mail {
    font-size: 16px;
  }
  &__privacy {
    font-size: 12px;
    opacity: .4;
    margin-top: 12px;
  }
  @media (max-width:991.98px) {
    &__phone {
      font-size: 14px;
    }
    &__mail {
      font-size: 14px;
    }
    &__privacy {
      font-size: 12px;
      margin-top: 6px;
    }
  }
  @media (max-width:767.98px) {
    &__phone {
      font-size: 12px;
    }
    &__mail {
      font-size: 12px;
    }
    &__privacy {
      font-size: 10px;
      margin-top: 0;
    }
  }
}

.foot-logo {
  display: block;
  position: relative;
  top: 4px;

  svg {
    width: 120px;
    height: 80px;
  }
}
@media (max-width:991.98px) {
  .foot {
    height: 60px;
    padding: 10px 0;
  }
  .foot-logo {
    svg {
      width: 90px;
      height: 60px;
    }
  }
}
@media (max-width:767.98px) {
  .foot {
    height: 40px;
    padding: 10px 0;
  }
  .foot-logo {
    svg {
      width: 60px;
      height: 40px;
    }
  }
}
