@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/proba-pro/ProbaPro-Regular.eot');
  src: local('Proba Pro Regular'), local('ProbaPro-Regular'),
  url('../fonts/proba-pro/ProbaPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proba-pro/ProbaPro-Regular.woff') format('woff'),
  url('../fonts/proba-pro/ProbaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/proba-pro/ProbaPro-Bold.eot');
  src: local('Proba Pro Bold'), local('ProbaPro-Bold'),
  url('../fonts/proba-pro/ProbaPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proba-pro/ProbaPro-Bold.woff') format('woff'),
  url('../fonts/proba-pro/ProbaPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/*
@font-face {
	font-family: 'Proba Pro';
	src: url('../fonts/proba-pro/ProbaPro-Italic.eot');
	src: local('Proba Pro Italic'), local('ProbaPro-Italic'),
		url('../fonts/proba-pro/ProbaPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-Italic.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proba Pro Lt';
	src: url('../fonts/proba-pro/ProbaPro-LightItalic.eot');
	src: local('Proba Pro Light Italic'), local('ProbaPro-LightItalic'),
		url('../fonts/proba-pro/ProbaPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-LightItalic.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proba Pro Md';
	src: url('../fonts/proba-pro/ProbaPro-Medium.eot');
	src: local('Proba Pro Medium'), local('ProbaPro-Medium'),
		url('../fonts/proba-pro/ProbaPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-Medium.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Proba Pro Lt';
	src: url('../fonts/proba-pro/ProbaPro-Light.eot');
	src: local('Proba Pro Light'), local('ProbaPro-Light'),
		url('../fonts/proba-pro/ProbaPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-Light.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proba Pro ExLt';
	src: url('../fonts/proba-pro/ProbaPro-ExtraLight.eot');
	src: local('Proba Pro ExtraLight'), local('ProbaPro-ExtraLight'),
		url('../fonts/proba-pro/ProbaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-ExtraLight.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Proba Pro';
	src: url('../fonts/proba-pro/ProbaPro-BoldItalic.eot');
	src: local('Proba Pro Bold Italic'), local('ProbaPro-BoldItalic'),
		url('../fonts/proba-pro/ProbaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-BoldItalic.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Proba Pro ExLt';
	src: url('../fonts/proba-pro/ProbaPro-ExtraLightItalic.eot');
	src: local('Proba Pro ExtraLight Italic'), local('ProbaPro-ExtraLightItalic'),
		url('../fonts/proba-pro/ProbaPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/proba-pro/ProbaPro-ExtraLightItalic.woff') format('woff'),
		url('../fonts/proba-pro/ProbaPro-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}
*/
