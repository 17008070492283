.photos-main {
  padding-top: 60px;
  @media (max-width: 1319.98px) {
    padding-top: 20px;
  }
  @media (max-width:767.98px) {
    padding-top: 60px;
  }
}


.pmb-desc {
  &__head {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 1.3px;
  }
  &__text p:first-child {
    margin-top: 0;
  }
}


.pm-block-1 {
  margin: 100px 0 0;
  display: flex;
  align-items: center;
  min-height: 560px;

  .pmb-photo-1__1 > span, .pmb-photo-1__2 > span {
    display: block;
    position: relative;
    background: no-repeat center;
    background-size: cover;
  }
  .pmb-photo-1__1 > span {
    z-index: 0;
    background-image: url(../uploads/pm-photo-3__1.png);
    width: 325px;
    height: 435px;
    top: -120px;
  }
  .pmb-photo-1__2 > span {
    z-index: 5;
    background-image: url(../uploads/pm-photo-3__2.png);
    width: 380px;
    height: 510px;
    left: -80px
  }

  @media (max-width: 1319.98px) {
    margin-top: 40px;
    .pmb-photo-1__1 {
      display: none;
    }
    .pmb-photo-1__2 > span {
      left: 0;
    }
    .pmb-desc {
      margin-left: 5%;
    }
  }

  @media (max-width:767.98px) {
    margin-top: -20px;
    flex-direction: column;

    .pmb-desc {
      margin: 0;
    }

    .pmb-photo-1__2 {
      width: 100%;
      margin-bottom: 10px;

      & > span {
        width: 100%;
        margin: 0 -20px;
        padding: 0 20px;
        height: 400px;
      }
    }
  }

}

.pm-block-2 {
  margin: 100px 0 0;
  display: flex;
  align-items: center;
  min-height: 560px;

  .pmb-desc {
    margin-right: 15%;
    margin-bottom: 200px;
  }

  .pmb-photo-2__1 > span, .pmb-photo-2__2 > span {
    display: block;
    position: relative;
    background: no-repeat center;
    background-size: cover;
  }
  .pmb-photo-2__1 > span {
    z-index: 0;
    background-image: url(../uploads/pm-photo-3__1.png);
    width: 325px;
    height: 435px;
    top: -120px;
  }
  .pmb-photo-2__2 > span {
    z-index: 5;
    background-image: url(../uploads/pm-photo-3__2.png);
    width: 380px;
    height: 510px;
    left: -80px;
  }

  @media (max-width: 1319.98px) {
    margin-top: 40px;
    .pmb-photo-2__2 {
      display: none;
    }
    .pmb-photo-2__1 > span {
      left: -10%;
    }
    .pmb-desc {
      margin-left: 5%;
    }
  }
  @media (max-width:991.98px) {
    margin-top: 150px;
    min-height: 300px;
  }
  @media (max-width:767.98px) {
    margin-top: 40px;
    flex-direction: column-reverse;

    .pmb-desc {
      margin: 0;
    }

    .pmb-photo-2__1 {
      width: 100%;
      margin-bottom: 10px;

      & > span {
        width: 100%;
        margin: 0 -20px;
        padding: 0 20px;
        height: 400px;
        left: 0;
        top: 0;
      }
    }
  }

}

.pm-block-3 {
  display: flex;
  align-items: center;
  min-height: 600px;

  .pmb-photo-3__1 > span, .pmb-photo-3__2 > span {
    display: block;
    position: relative;
    background: no-repeat center;
    background-size: cover;
  }
  .pmb-photo-3__1 > span {
    z-index: 0;
    background-image: url(../uploads/pm-photo-3__1.png);
    width: 450px;
    height: 600px;
    left: 0;
  }
  .pmb-photo-3__2 > span {
    z-index: 5;
    background-image: url(../uploads/pm-photo-3__2.png);
    width: 382px;
    height: 510px;
    left: -150px;
  }

  @media (max-width: 1319.98px) {
    margin-top: -200px;
    .pmb-photo-3__1 {
      display: none;
    }
    .pmb-photo-3__2 > span {
      left: 0;
    }
    .pmb-desc {
      margin-left: 5%;
    }
  }
  @media (max-width:991.98px) {
    margin-top: -95px;
  }
  @media (max-width:767.98px) {
    margin-top: 40px;
    flex-direction: column;

    .pmb-desc {
      margin: 0;
    }

    .pmb-photo-3__2 {
      width: 100%;
      margin-bottom: 10px;

      & > span {
        width: 100%;
        margin: 0 -20px;
        padding: 0 20px;
        height: 400px;
      }
    }
  }

}

.pm-block-4 {
  display: flex;
  min-height: 520px;
  margin-top: 20px;
  margin-bottom: 60px;

  .pmb-desc {
    width: 35%;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 150px;
  }

  .pmb-photo-4__bg-gray {
    position: relative;
    height: 345px;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      right: -1000px;
      top: 0;
      height: inherit;
      background: #f5f5f5;
    }
  }

  .pmb-photo-4__1, .pmb-photo-4__2 {
    position: absolute;
    z-index: 10;
    border: 6px solid #C7C7C7;
    padding: 6px;
    top: 114px;
    left: 55px;
  }
  .pmb-photo-4__2 {
    border-color: rgba(0, 168, 116, 0.301983);
    left: 396px;
  }
  .pmb-photo-4__1 > div,.pmb-photo-4__2 > div {
    display: block;
    background: url(../uploads/pm-photo-4__1.png) no-repeat center;
    background-size: cover;
    width: 272px;
    height: 354px;
  }
  .pmb-photo-4__1 > div > span, .pmb-photo-4__2 > div > span {
    position: absolute;
    bottom: -33px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
  }

  @media (max-width: 1399.98px) {
    .pmb-desc {
      width: 45%;
    }
    .pmb-photo-4__2 {
      display: none;
    }
  }
  @media (max-width: 1023.98px) {
    .pmb-desc {
      width: auto;
      margin-left: 0;
      margin-right: 4%;
    }
    .pmb-photo-4__bg-gray {
      flex: 0 0 340px;
    }
  }
  @media (max-width: 768.98px) {
    margin-top: 40px;
    flex-direction: column-reverse;
    .pmb-desc {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .pmb-photo-4__1 {
      top: 55px;
      left: 40px;
    }
  }
}

