body.-open-popup-text > .-hid {
  transition: filter 1s;
  filter: blur(10px);
}
body.-open-popup-text .popup-text {
  @include fadeOut(.5s);
}

//todo poput text and popup form
.pt-load {
  @include fade(.5s);

  .popup-text.-load & {
    @include fadeOut(.5s);
  }

  .sk-fading-circle {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
}

.popup-text {
  position: fixed;
  top: 100px;
  bottom: 100px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  z-index: $zIndexPopup;
  @include fade(.5s);

  .pt-cont {
    height: 100%;
  }
  .static-page {
    max-height: 100%;
    overflow: auto;
    padding-right: 20px;
    margin-right: -20px;
  }

  @media (max-width: 839.98px) {
    left: 40px;
    right: 40px;
    margin-left: 0;
    width: auto;
  }

  @media (max-width: 399.98px) {
    left: 20px;
    right: 20px;
    margin-left: 0;
    width: auto;
  }

  .pt-overlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
  }
  .pt-cont {
    position: relative;
    z-index: 10;
  }
  .pt-close {
    position: fixed;
    top: 50px;
    right: 50px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.pt-cont {
  background: #fff;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: $boxShadow;
  margin-bottom: 100px;
//todo
  @include fade(.5s);
  display: none;

  .popup-text.-loaded & {
    display: block;
    @include fadeOut(.5s);
  }

}