$slideHeight: 515px;
$slideHeightMob: 540px;
$bottomPad: 90px;
$bottomPadMob: 140px;

.trip {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  .container:after {
    content: '';
    display: block;
    clear: both;
  }
}
.trip-container {
  margin: 0 -2.33%;
}

.trip-carousel {
  height: $slideHeight + $bottomPad;
  outline: none;
  * > {
    outline: none;
  }

  .slick-dots {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      margin: 0 3px;
      width: 10px;
      height: 10px;
      border: 1px solid rgba(59, 70, 74, 0.448481);;
      border-radius: 50%;
      cursor: pointer;

      button {
        opacity: 0;
      }

      &.slick-active {
        border-color: #3b464a;
        background: #3b464a;
      }
    }
  }
}

.trip-item {
  float: left;
  width: 33.33%;
  cursor: pointer;
  margin-bottom: 40px;
}
.trip-item > div {
  padding: 0 7%;
}
.trip-item-desc {
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  width: 100%;
  height: $slideHeight;
  background: url(../uploads/trip.png) no-repeat center;
  background-size: cover;
  color: #fff;
  position: relative;

  & > div {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
  }
}
.trip-item-desc__head {
  line-height: 1.3;
  font-size: 30px;
}
.trip-item-desc__text {
  display: none;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 20px;
}
.trip-item-desc__price {
  display: none;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 20px;
}
.trip-item:hover {
  .trip-item-desc__text {
    display: block;
  }
  .trip-item-desc__price {
    display: block;
  }
}

.trip-item-btn {
  margin-top: 30px;
  text-align: center;

  .btn {
    min-width: 150px;
  }
}

@media(max-width: 1161.98px) {
  .trip-item > div {
    padding: 0 20px;
  }
  .trip-item {
    width: 50%;
  }
}
@media(max-width: 767.98px) {
  .trip {
    padding-top: 0;
    margin-top: 40px;
  }
  .trip-item > div {
    padding: 0;
    margin: 0 -2px;
  }
  .trip-item {
    width: 100%;
  }
  .trip-container {
    margin: 0 -20px;
  }
  .trip-item-btn {
    margin-top: 65px;
  }
  .trip-item-desc {
    height: $slideHeightMob;
  }
  .trip-carousel {
    height: $slideHeightMob + $bottomPadMob;
    .slick-dots {
      position: relative;
      top: -125px;
    }
  }
}