.about {
  padding: 120px 0;
  position: relative;
}
.about-pict {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 720px;
  height: 520px;
  margin-top: -260px;
  background: url(../uploads/about-ban.jpg) no-repeat center;
  background-size: cover;
}
.about-desc {
  box-sizing: border-box;
  width: 60%;
  position: relative;
  padding: 250px 17% 250px 0;
  min-height: 250px;
  & > * {
    position: relative;
    z-index: 10;
  }
}
.about-desc:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: -2000px;
  background: #F5F5F5;
}
.about-desc__head {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  line-height: 1.2;
}

@media (max-width: 1439.98px) {
  .about-desc {
    padding-top: 120px;
    padding-bottom: 120px;
    width: 70%;
  }
  .about-pict {
    left: 60%;
    height: 370px;
    width: 515px;
    margin-top: -185px;
  }
}

@media (max-width: 991.98px) {
  .about {
    padding: 60px 0;
  }
  .about-desc {
    width: 80%;
    padding: 150px 17% 150px 5%;
  }
  .about-pict {
    left: 70%;
  }
}

@media (max-width: 767.98px) {
  .about {
    margin-top: 40px;
    padding: 20px 0 60px 0;
    background: #F5F5F5;
  }
  .about-desc {
    width: 100%;
    padding: 0 0 30px;
    &:after {
      display: none;
    }
  }
  .about-pict {
    left: 0;
    top: 0;
    margin-top: 0;
    position: relative;
    padding-top: 70%;
    height: auto;
    width: 100%;
  }
  .about-desc__head {
    font-size: 24px;
  }
  .about-desc__text {
    font-size: 16px;
  }
}