$viewMinHeight: 610px;
$viewMinHeightTab: 550px;
$viewMinHeightMob: 450px;

.view {
  position: relative;
}

.view-slider {
  position: relative;
  user-select: none;
  margin: 20px 0;

  &__img {
    position: relative;
    z-index: 0;
    width: 100%;
    height: $viewMinHeight;
    overflow: hidden;
    background: no-repeat center  #f4f4f4;
    background-size: cover;
  }
  &__label360 {
    position: absolute;
    z-index: 5;
    right: 20px;
    top: 20px;
    transform: scale(0.75);
  }
  &__nav {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: #000;
    height: 30px;
    line-height: 30px;
    color: #C7C7C7;
    padding: 0 10px;

    & > * {
      display: inline-block;
    }
    &__nav__count {
      padding: 0 10px;
    }
    &__nav__prev, &__nav__next {
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
    &__nav__prev {
      border-top: 2px solid #c7c7c7;
      border-left: 2px solid #c7c7c7;
      transform: rotate(-45deg);
    }
    &__nav__next {
      border-top: 2px solid #c7c7c7;
      border-right: 2px solid #c7c7c7;
      transform: rotate(45deg);
    }
  }
}

.view-capsule {
  & p {
    margin: 15px 0;
    font-size: 14px;
  }
  &__head,
  &__sub-head {
    font-weight: 700;
    margin: 0 0 15px;
    color: #333;
  }

  &__price {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    span {
      font-size: 26px;
      padding-left: 15px;
    }
  }
  &__btn {
    margin: 20px 0;
  }

  &__service {
    margin-top: 10px;
    line-height: 52px;
  }
  &__service svg {
    width: 52px;
    height: 52px;
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media (min-width: 991.98px) {
  $viewSliderWidth: 50%;
  .view-capsule,
  .view-tabs-nav {
    padding-left: $viewSliderWidth + 5%;
  }
  .view {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: $viewMinHeightTab;
  }
  .view-slider__img {
    height: $viewMinHeightTab;
  }
  .view-slider {
    top: -10px;
    position: absolute;
    left: 0;
    top: -50px;
    width: $viewSliderWidth;
  }
  .view-capsule__name {
    display: none;
  }
}

@media (min-width: 1439.98px) {
  .view {
    padding-top: 130px;
    padding-bottom: 60px;
    min-height: $viewMinHeight;
  }

  .view-slider {
    top: -50px;
  }
}

@media (max-width: 991.98px) {
  .view {
    min-height: $viewMinHeightMob;
  }
  .view-slider__img {
    height: $viewMinHeightMob;
  }

  .view-tabs-nav {
    position: absolute;
    z-index: 10;
    top: 85px;
    left: 15px;
    counter-reset: num;

    li {
      margin: 0 2px;
    }
    li a {
      padding: 0;
      display: block;
      overflow: hidden;
      width: 24px;
      height: 24px;
      text-indent: -1000px;
      border: 1px solid #3b464a;
      color: #3b464a;
      position: relative;
      &:after {
        counter-increment: num;
        content: counter(num);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        line-height: 24px;
        text-align: center;
        text-indent: 0;
      }
    }
  }
  .view-capsule__name {
    margin: 0;
    padding: 50px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    letter-spacing: 0.288px;
    height: 26px;
    line-height: 26px;
  }
  .view-slider {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (max-width: 767.98px) {
  .view {
    min-height: $viewMinHeightMob;
  }
  .view-slider__img {
    height: $viewMinHeightMob;
  }

  .view-slider {
    margin-left: -20px;
    margin-right: -20px;
  }
}
