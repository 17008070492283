.tabs__nav {
  margin: 35px -15px;
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: #333;
  line-height: 30px;

  li {
    display: inline-block;
    margin: 15px 15px;
    a {
      display: block;
      background: #F5F5F5;
      border-radius: 15px;
      padding: 0 20px;
      cursor: pointer;
      transition: box-shadow .5s, background .5s, color .5s;

      &:focus, &:hover {
        box-shadow: $btnBoxShadow;
      }
    }
    &.-act a {
      background: #3B464A;
      color: #fff;
    }
  }
}

.tabs__cont {

}
.tabs__cont__tab {
  display: none;
}
.tabs__cont__tab.-show {
  display: block;
}
/*
.tabs__cont__tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  @include fade(.5s);
}
.tabs__cont__tab.-show {
  z-index: 1;
  position: relative;
  @include fadeOut(.5s);
}
*/