.static-page {
}


.sp__head {
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .sp__head {
    font-size: 22px;
  }
}