html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  text-rendering: optimizeLegibility;

  background: #fff;
  min-height: 100%;
}
body {
  font-family: 'Proba Pro';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #3B464A;
  user-select: none;

  @include minWidth();
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 25px 0;
}

p a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.-none {
  display: none;
}
.-svg-use {
  display: inline-block;
}
.-hid {
  overflow: hidden;
}
.-block {
  display: block;
}
.-relative {
  position: relative;
}

.bg-gray {
  background: $gray;
}

.btn {
  border: 0;
  display: inline-block;
  background: $green;
  border-radius: 22px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 12px 40px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  transition: box-shadow .5s;

  &:focus, &:hover {
    box-shadow: $btnBoxShadow;
  }
}

.btn-sm {
  border: 0;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 44px;
  display: inline-block;
  background: $green;
  border-radius: 22px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  transition: box-shadow .5s;

  &:focus, &:hover {
    box-shadow: $btnBoxShadow;
  }
}

.sk-fading-circle {
  $circleCount: 12;
  $animationDuration: 1.2s;
  $spinkit-size: 40px;
  $spinkit-spinner-color: $green;

  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: auto;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    animation: sk-fading-circle-delay $animationDuration infinite ease-in-out both;
  }

  @for $i from 2 through $circleCount {
    .sk-circle-#{$i} {
      transform: rotate((360deg / $circleCount * ($i - 1)));
    }
  }

  @for $i from 2 through $circleCount {
    .sk-circle-#{$i}:before {
      animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
    }
  }

}

@keyframes sk-fading-circle-delay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
