.banner {
  margin: $menuHeight 0 0 0;
  color: #fff;
  width: 100%;
  background: no-repeat center;
  background-size: cover;
  min-height: 674px;

  .container {
    min-height: inherit;
  }

  @media (min-width:768px) {

  }
  @media (max-width:991.98px) {
    //margin-top: $menuHeightTab;
    margin-top: 0;
    min-height: 100vh;
  }
  @media (max-width:767.98px) {
    margin-top: $menuHeightMob;
    height: 490px;
  }
}
.banner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: inherit;
}
.banner-text {
  max-width: 480px;
  padding-left: 10%;
  padding-right: 10%;
  margin: 100px 0;

  &__btn {
    margin-top: 40px;
    .btn {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  h2 {
    font-size: 90px;
    letter-spacing: 4px;
    margin: 0;

    #banner-head-svg {
      width: 144px;
      height: 89px;
      position: relative;
      top: 26px;
    }
  }
  p {
    font-size: 18px;
    line-height: 1.7;
    letter-spacing: 0.288px;
    margin: .8em 0;
  }

  @media(max-width:991.98px) {
  }
  @media(max-width:767.98px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 240px;
    margin: 0 auto;

    &__btn {
      text-align: center;
      margin-top: 20px;
      .btn {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    h2 {
      font-size: 40px;
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 12px;
      margin: .5em 0;
    }
  }
}

.banner-right {
  flex: 0 0 360px;
  @media (max-width:991.98px) {
    display: none;
  }
}
