.photos-capsules {
  position: relative;
  width: 100%;
  height: 700px;
}

.pc-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include fade(.5s);
  z-index: 0;

  &.-show {
    @include fadeOut(.5s);
    z-index: 10;
  }

  .pc-item__desc-box {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .container {
    position: relative;
  }

  &__photos {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;

     & div {
       height: 100% !important;
     }

    &__item, &__item > span {
      display: block;
      width: 100%;
      height: 100%;
      background: no-repeat center;
      background-size: cover;
    }
  }

  &__desc {
    color: #fff;
    position: absolute;
    bottom: 80px;
    left: 50px;
    width: 33%;

    &__head {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 45px;
      letter-spacing: 2px;
    }
    &__text {
      font-size: 18px;
      letter-spacing: 1px;
    }
  }

  &__nav {
    margin-top: 45px;
    font-size: 13px;
    border: 1px solid #fff;
    width: 130px;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    overflow: hidden;

    &__next, &__prev {
      float: left;
      width: 50%;
      text-align: center;
      position: relative;
    }
    &__prev:after {
      content: '';
      position: absolute;
      left: 100%;
      top: -20px;
      bottom: -20px;
      width: 1px;
      background: #fff;
      transform: rotate(27deg);
    }
  }
}

.pc-galleries {
  position: absolute;
  bottom: 55px;
  width: 60%;
  right: 0;
  text-align: right;
  z-index: 10;

  &__photo {
    display: inline-block;
    width: 225px;
    height: 150px;
    background: no-repeat center;
    background-size: cover;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    transition: filter .5s;
    filter: grayscale(100%);

    &:hover, &.-act {
      filter: grayscale(0);
    }

    & > span {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px 10px;
      background: rgba(0, 0, 0, 0.755818);
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

  }
}

@media (max-width: 1319.98px) {
  .photos-capsules {
    margin-bottom: 180px;
  }
  .pc-galleries {
    bottom: -180px;
    right: auto;
    left: 50px;
    width: auto;
  }
  .pc-item {
    &__desc {
      width: 50%;
    }
  }
}

@media (max-width: 991.98px) {
  .photos-capsules {
    margin-bottom: 180px;
  }
  .pc-galleries {
    bottom: -180px;
    left: 50px;
    right: 50px;
    width: auto;
    margin: 0 -1.5%;

    &__photo {
      width: 30.33%;
      margin: 0 1.5%;
      float: left;
    }

  }
}

@media (max-width: 767.98px) {
  .photos-capsules {
    height: calc(100vh - 45px);
    margin-bottom: 0;
    margin-top: -20px;
  }
  .pc-galleries {
    top: 20px;
    bottom: auto;
    left: 20px;
    right: auto;
    margin: 0;
    float: left;
    width: auto;

    &__photo {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 0;
      span {
        display: none;
      }
    }

  }

  .pc-item {
    &__desc {
      width: auto;
      bottom: 20px;
      left: 20px;
      right: 20px;
    }
    &__desc__text {
      font-size: 14px;
      margin: 0;
      p {
        margin: 5px 0 0;
      }
    }
    &__desc__head {
      font-size: 16px;
      margin-bottom: 10px;
    }
    &__nav {
      display: none;
    }
  }
}


@media (min-width: 1320px) {
  .photos-capsules {
    height: calc(100vh - 140px);
  }
}